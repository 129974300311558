import Vue from 'vue'
import Router from 'vue-router'

const routerOptions = [

    // ================================================ Root =======================================================

    // ================================================ Profile =======================================================
    { path: '/webportal/createwebportal', name: 'CreateWebPortal', componentPath: 'WebPortal/CreateWebPortal' },
    { path: '/', name: 'IndivdualQuestion', componentPath: 'WebPortal/IndividualQuestion'},
]

const routes = routerOptions.map(route => {
    return {
        ...route,
        component: () => import(`@/components/${route.componentPath}.vue`)
    }
})

Vue.use(Router)

export default new Router({
    routes,
    base: process.env.BASE_URL,
    mode: 'history'
})