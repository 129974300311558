import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import DatePicker from "vue2-datepicker"
// import 'vue2-datepicker/index.css';
import { SweetModal, SweetModalTab } from "sweet-modal-vue";
import VueStarRating from 'vue-star-rating';
import VeeValidate from "vee-validate";
import VModal from 'vue-js-modal'
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import Vuex from "vuex";
import axios from 'axios'
import InputTag from "vue-input-tag";
var VueCookie = require("vue-cookie");

Vue.use(VueCookie);
Vue.config.productionTip = false
Vue.component("date-picker", DatePicker);
// Vue.use(Element)
Vue.component("multiselect", Multiselect);
Vue.component("input-tag", InputTag);
Vue.use(SweetModal);
Vue.use(SweetModalTab);
Vue.use(ElementUI);
Vue.use(VeeValidate);
Vue.use(axios);
Vue.use(Vuex);
Vue.use(VModal);

Vue.component('star-rating', VueStarRating)

Vue.prototype.$serverUrl = "https://adminapis.digicollect.com";
Vue.prototype.$http = axios.create({
  baseURL: Vue.prototype.$serverUrl,
});

new Vue({
  router,
  store,
  components: {
    SweetModal,
    SweetModalTab
  },
  render: h => h(App)
}).$mount('#appsurvey')
